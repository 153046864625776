<template lang="pug">
.tournaments
  router-link.link(
    v-for="tournament in tournaments" key:="tournament.id"
    :to="{ name: 'games', params: { tournamentId: tournament.id } }"
    )
    .tournamenBox
      .name {{tournament.name}}
      .description(v-if="tournament.description")
      .date {{ tournament.dateStart }}
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Tournaments',
  computed: {
    ...mapState(['tournaments']),
    ...mapGetters(['getTournaments']),
    tournaments: (store) => store.getTournaments,
  },
  mounted() {
    this.$store.dispatch('tournaments');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">

.link
  text-decoration none

.tournamenBox
  border-radius 12px
  margin: 8px
  padding: 24px
  background-color #F5F5F8
  text-align center

.name
  font-size 18px
</style>
