import { AxiosApi } from '@/services/axios-api';

export default {
  state: {
    games: [],
  },
  mutations: {
    setGames(state, games) {
      state.games = games;
    },
    cleanGames(state) {
      state.games = [];
    },
  },
  getters: {
    getGames: (state) => state.games,
  },
  actions: {
    async games(context, tournamentId) {
      context.commit('cleanGames');
      const url = `/tournaments/${tournamentId}/games/`;
      await AxiosApi.get(url).then(({ data }) => {
        context.commit('setGames', data.records);
      });
    },
  },
};
