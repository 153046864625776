<template lang="pug">
button.button--logout(@click="logout") Logout
</template>

<script>
import { userLogout } from '@/services/auth-service';
import { AxiosApi } from '@/services/axios-api';

export default {
  name: 'LogoutButton',
  methods: {
    logout() {
      userLogout(AxiosApi);
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped lang="stylus">
button.button--logout
  width 50%
  padding 10px 20px
</style>
