<template lang="pug">
.game-info
    .section(v-if="isEditing")
        GameInfoEdit(:gameSession="gameSession" @confirm-game-session="confirmGameSession" @cancel-edit="isEditing = !isEditing")
    .section(v-else @click="isEditing = !isEditing")
        .score-box
            .name-team(:class="gameSession.homeTeam.id == gameSession.attackTeamId ? 'has-ball' : null") {{ gameSession.homeTeam.name }}
            .info
                .score {{ gameSession.homeScore }}
                span.score :
                .score {{ gameSession.awayScore }}
            .name-team(:class="gameSession.awayTeam.id == gameSession.attackTeamId ? 'has-ball' : null") {{ gameSession.awayTeam.name }}
        .info-box
            .half(v-if="!!gameSession.gameInProgress && gameSession.half <= 2") {{ gameSession.half }} Половина
            .half(v-else-if="!!gameSession.gameInProgress") Овертайм
            .half(v-else-if="gameSession.finished") Игра закончена
            .half(v-else) Игра не идет
            .down(v-if="!!gameSession.currentDown") {{ gameSession.currentDown }} Down & {{ gameSession.targetDowns }}
</template>

<script>
import GameInfoEdit from '@/components/liveGames/GameInfoEdit'
export default {
  name: 'GameInfo',
  components: { GameInfoEdit },
  data() {
    return {
        isEditing: false,
    }
  },
  methods: {
    confirmGameSession(data) {
        this.isEditing = false;
        this.$emit('change-game-session', data);
    }
  },
  props: {
    gameSession: {
      type: Object,
    },
  },
};
</script>

<style scope lang="stylus">
.game-info
    padding 5px 10px
    margin-bottom 20px
    border-radius: 5px
    background-color #F5F5F8
.score-box
    padding-bottom 5px
    display flex
    justify-content space-between
    align-items center
    gap: 5px
    border-bottom 2px solid #000
.info
    display flex
    justify-content space-between
    span .score
        margin 0 2px
.info-box
    margin 10px 5px
.name-team
    width 40%
    font-size 20px
    text-align center
.score
    font-size 20px
    border 3px
.has-ball
    padding 2px 4px
    border-radius 4px
    border 3px solid #009063
.half
.down
    text-align center
.half
    margin-top 5px
</style>
