<template lang="pug">
.choose-team
    .p Выберете атакующую команду
    button.team(
        @click="setAttackTeamEmit(gameSession.homeTeam.id, gameSession.flowInfo[0].eventType)"
        :class="gameSession.homeTeam.id == gameSession.attackTeamId ? 'has-ball' : null"
    ) {{ gameSession.homeTeam.name }}
    button.team(
        @click="setAttackTeamEmit(gameSession.awayTeam.id, gameSession.flowInfo[0].eventType)"
        :class="gameSession.awayTeam.id == gameSession.attackTeamId ? 'has-ball' : null"
    ) {{ gameSession.awayTeam.name }}
</template>

<script>
export default {
  name: 'ChooseTeam',
  props: {
    gameSession: {
      type: Object,
    },
  },
  methods: {
    setAttackTeamEmit(teamId, eventType) {
      this.$emit('set-attack-team', teamId, eventType);
    },
  },
};
</script>

<style scope lang="stylus">
.players-list
    display flex
    flex-direction: column
    height calc(100vh - 90px)
</style>
