import { createStore } from 'vuex';
import gamesList from './components/gamesList';
import tournamentsList from './components/tournamentsList';
import liveGame from './components/gameSession';

export default createStore({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    gamesList,
    tournamentsList,
    liveGame,
  },
});
