<template lang="pug">
.live-game
    .stats
        LogoutButton
        .session-info
          p id: {{ gameSession.gameId }}
          p {{ gameSession.eventId ? gameSession.eventId : 'New' }}
    .game-screen(v-if="gameSession.finished")
      button(@click="leaveGame") Выйти из игры
    .game-screen(v-else-if="gameSession.gameId")
        .main-screen(v-if="gameSession.flowInfo[0].eventType == 'confirm_roster'")
          .player-list(v-if="setRoster")
            PlayersList(
              :team="setRoster.currentTeam === 'home' ? gameSession.homeTeam : gameSession.awayTeam "
              :isEditable="!!setRoster"
              :game_id="gameSession.gameId"
              :setRosterLabel="setRoster.label"
              @submit-create-player="submitCreatePlayer"
              @submit-edit-player="submitEditPlayer"
              @submit-delete-player="submitDeletePlayer"
              @confirm-roster="confirmRoster"
            )
        .main-screen(v-else-if="screenDisplay.showChooseTeam")
                ChooseTeam(:gameSession="gameSession", @set-attack-team="setAttackTeam")
        .main-screen(v-else-if="screenDisplay.choosePlayerTeam")
                PlayersList(
                    :eventData="screenDisplay.choosePlayerTeam.eventData"
                    :team="screenDisplay.choosePlayerTeam.team"
                    isEditible=false
                    :gameId="gameSession.gameId"
                    @player-choosen="chooseenPlayer"
                )
        .main-section(v-else-if="screenDisplay.additionalInfo")
            h4 {{ screenDisplay.additionalInfo.label }}
            button(v-for="button in screenDisplay.additionalInfo.additionalInfo.actions" @click="processGameEvent(button, gameSession.eventId)") {{ button.label }}
        .main-section(v-else-if="screenDisplay.flag")
            GameInfo(:gameSession="gameSession" @change-game-session="changeGameSession")
            Flag(
              :homeTeam="gameSession.homeTeam"
              :awayTeam="gameSession.awayTeam"
              :currentDown="gameSession.currentDown"
              :targetDowns="gameSession.targetDowns"
              :attackTeamId="gameSession.attackTeamId"
              @submit-flag="submitFlag"
            )
        .main-screen(v-else)
            GameInfo(:gameSession="gameSession" @change-game-session="changeGameSession")
            .main-actions
                .sos-actions
                    button.cancel(@click="cancelPlay") {{ gameSession.eventId ? "Отменить текущий рощыгрыш" : "Отменить последний розыгрыш" }}
                .action-buttons
                    button(v-for="button in gameSession.flowInfo" :key="button.eventType" @click="processGameEvent(button, gameSession.eventId)") {{ button.label }}
    .not-found-game(v-else) Game Not found.
        BackButton(backUrlName="tournaments")
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import GameInfo from '@/components/liveGames/GameInfo';
import GameInfoEdit from '@/components/liveGames/GameInfoEdit';
import ChooseTeam from '@/components/liveGames/ChooseTeam';
import Flag from '@/components/liveGames/Flag';
import PlayersList from '@/components/players/PlayersList';
import BackButton from '@/components/appSections/BackButton';
import LogoutButton from '@/components/auth/LogoutButton';

export default {
  name: 'LiveGame',
  data() {
    return {
      setRoster: {
        currentTeam: 'home',
        label: 'Подтвердить состав',
      },
    };
  },
  components: {
    GameInfoEdit,
    GameInfo,
    PlayersList,
    BackButton,
    LogoutButton,
    ChooseTeam,
    Flag,
  },
  methods: {
    leaveGame() {
      this.$router.push({name: "tournaments"})
    },
    confirmRoster() {
      if (this.setRoster.currentTeam === 'home') {
        this.setRoster.currentTeam = 'away';
      } else {
        this.setRoster = null;
        this.$store.dispatch('postNewEvent', {
          game_id: this.$route.params.liveGameId,
          event_type: this.gameSession.flowInfo[0].eventType,
        });
      }
    },
    setAttackTeam(teamId, eventType) {
      if (!this.gameSession.readyToGame) {
        const data = {
          teamId,
          eventType,
          game_id: this.$route.params.liveGameId,
        };
        this.$store.dispatch('postNewEvent', data);
      }
    },
    processGameEvent(eventInfo, eventId) {
      const eventData = {
        eventType: eventInfo.eventType,
        gameId: this.$route.params.liveGameId,
        isAdditionalInfo: eventInfo.isAdditionalInfo,
      };
      if (eventId) {
        eventData.eventId = eventId;
      }
      if (eventInfo.eventType === 'flag') {
        this.$store.dispatch('runFlagProcess', eventData);
        return;
      }
      if (eventInfo.needPlayerIdFromTeam) {
        const data = {
          eventData,
        };
        data.team = this.getChooseTeam(eventInfo, this.gameSession);
        this.$store.dispatch('updateChoosePlayerTeam', data);
        return;
      }
      if (eventInfo.additionalInfo && !eventInfo.isAdditionalInfo) {
        eventData.additionalInfo = eventInfo.additionalInfo;
        this.$store.dispatch('showAdditionalInfo', eventData);
        return;
      }
      if (eventId) {
        this.$store.dispatch('updateEvent', eventData);
      } else {
        this.$store.dispatch('postNewEvent', eventData);
      }
    },
    chooseenPlayer(data) {
      if (data.eventId) {
        this.$store.dispatch('updateEvent', data);
      } else {
        this.$store.dispatch('postNewEvent', data);
      }
    },
    cancelPlay() {
      const data = {
        gameId: this.gameSession.gameId,
      };
      if (this.gameSession.eventId) data.eventId = this.gameSession.eventId
      this.$store.dispatch('cancelPlaySubmit', data);
    },
    finishGame() {
      this.$store.dispatch('setEmptyGameSession');
      this.$router.push({ name: 'tournaments' });
    },
    getChooseTeam(eventInfo, gameSession) {
      if (eventInfo.needPlayerIdFromTeam == 'attack') {
        return gameSession.attackTeamId == gameSession.homeTeam.id
          ? gameSession.homeTeam
          : gameSession.awayTeam;
      }
      if (eventInfo.needPlayerIdFromTeam == 'defense') {
        return gameSession.attackTeamId == gameSession.homeTeam.id
          ? gameSession.awayTeam
          : gameSession.homeTeam;
      }
      return null;
    },
    submitFlag(data) {
      data.gameId = this.gameSession.gameId;
      this.$store.dispatch('flagSubmitAction', data);
    },
    changeGameSession(data) {
      data.gameId = this.gameSession.gameId;
      this.$store.dispatch('changeGameSessionAction', data);
    },
    submitCreatePlayer(player, teamId) {
      const data = {
        gameId: this.gameSession.gameId,
        teamId,
        player,
      }
      this.$store.dispatch("addPlayerAction", data);
    },
    submitEditPlayer(player, teamId) {
      const data = {
        gameId: this.gameSession.gameId,
        teamId,
        player,
      }
      this.$store.dispatch("editPlayerAction", data);
    },
    submitDeletePlayer(data) {
      data.gameId = this.gameSession.gameId;
      this.$store.dispatch("deletePlayerAction", data);
    },

  },
  computed: {
    ...mapState(['gameSession', 'screenDisplay']),
    ...mapGetters(['getGameSession', 'getScreenDisplay']),
    gameSession: (store) => store.getGameSession,
    screenDisplay: (store) => store.getScreenDisplay,
  },
  mounted() {
    this.$store.dispatch('gameSession', this.$route.params.liveGameId);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.stats
  display flex
  justify-content space-between
  margin-bottom 10px
.session-info
  p
    text-align center
    margin 0
.sos-actions
    .cancel
        background-color #FFDDED
    .review
        background-color #7BEDFE
    .flag
        background-color #F9F871
    margin-bottom 20px
    display flex
    justify-content space-between
    gap 10px
.has-ball
    background-color brown
    color white
.main-actions
    margin-bottom 20px
.action-buttons
  display flex
  flex-direction column
  gap 15px
.choose-team
  display flex
  flex-direction column
  gap 10px
.choose-player
    flex-grow 1
.review:disabled
  color ccc
</style>
