import axios from 'axios';
import { getToken, isAuthorizated, refreshToken } from './auth-service';

const hostName = process.env.VUE_APP_BACKEND_URL || window.location.origin;

const baseUrl = `${hostName}${process.env.VUE_APP_BASE_URL}`;

const AxiosApi = axios.create({
  baseURL: baseUrl,
  timeout: 1000,
});

function snakeToCamel(obj) {
  if (obj instanceof Array) {
    return obj.map(snakeToCamel);
  } if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/_(\w)/g, (_, p1) => p1.toUpperCase());
      acc[camelKey] = snakeToCamel(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

function camelToSnake(obj) {
  if (obj instanceof Array) {
    return obj.map(camelToSnake);
  } if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (match) => `_${match.toLowerCase()}`,
      );
      acc[snakeKey] = camelToSnake(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

AxiosApi.defaults.headers.common.Accept = 'application/json';
AxiosApi.interceptors.request.use(async (config) => {
  if (config.data) {
    config.data = camelToSnake(config.data);
  }
  if (config.url === '/auth/login/' || config.url === '/auth/refresh/') {
    return config;
  }
  const authStatus = isAuthorizated(AxiosApi);
  if (!authStatus) {
    const controller = new AbortController(AxiosApi);
    controller.abort();
    window.location = '/login/';
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

AxiosApi.interceptors.response.use(
  (response) => {
    response.data = snakeToCamel(response.data);
    return response;
  },
  async (error) => {
    if (error) {
      const originalRequest = error.config;
      if (
        error.response.status === 401
        && error.response.data.detail === 'access_token_expired'
        // eslint-disable-next-line no-underscore-dangle
        && !originalRequest._retry
      ) {
        originalRequest._retry = true;
        // eslint-disable-next-line no-underscore-dangle
        isAuthorizated(AxiosApi).then((result) => {
          if (result) {
            return AxiosApi(originalRequest);
          }
        });
        if (isAuthorizated(AxiosApi)) {
          return AxiosApi(originalRequest);
        }
      }
      if (error.response.status === 401 && error.config.url === '/login/') {
        window.location = '/login/';
      }
    }
    return Promise.reject(error);
  },
);

// eslint-disable-next-line import/prefer-default-export
export { AxiosApi };
