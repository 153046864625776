import { AxiosApi } from '@/services/axios-api';

export default {
  state: {
    gameSession: {
      gameId: null,
      homeTeam: {
        id: null,
        name: '',
        players: [
          {
            id: null,
            firstName: '',
            lastName: '',
            jerseyNumber: '',
          },
        ],
      },
      awayTeam: {
        id: null,
        name: '',
        players: [
          {
            id: null,
            firstName: '',
            lastName: '',
            jerseyNumber: '',
          },
        ],
      },
      homeScore: 0,
      awayTeamScore: 0,
      attackTeamId: 0,
      half: 0,
      currentDown: 0,
      gameInProgress: false,
      finished: false,
      flowInfo: {},
      targetDowns: 'center',
      readyToGame: false,
      eventId: null,
    },
    screenDisplay: {
      showChooseTeam: false,
      choosePlayerTeam: null,
      additionalInfo: null,
      flag: null
    },
  },
  mutations: {
    setGameSession(state, gameSession) {
      state.gameSession = gameSession;
    },
    clearGameSession(state) {
      state.gameSession = {
        gameId: null,
        homeTeam: {
          id: null,
          name: '',
          players: [],
        },
        awayTeam: {
          id: null,
          name: '',
          players: [],
        },
        homeScore: 0,
        awayTeamScore: 0,
        attackTeamId: 0,
        half: 0,
        down: 0,
        gameInProgress: false,
        finished: false,
        flowInfo: {
          eventType: '',
          label: '',
          needTeam: false,
          needPlayerIdFromTeamEnum: 0,
        },
      };
    },
    setSelectStartScreenDisplay(state, gameSession) {
      if (gameSession.flowInfo.length) {
        if (gameSession.flowInfo[0].eventType === 'set_attacking_team') {
          state.screenDisplay.showChooseTeam = true;
        } else {
          state.screenDisplay.showChooseTeam = false;
        }
        state.screenDisplay.choosePlayerTeam = null;
        state.screenDisplay.additionalInfo = null;
        state.screenDisplay.flag = null;
      }
    },
    setScreenDisplayChooseTeam(state, showChooseTeam) {
      state.screenDisplay.showChooseTeam = showChooseTeam;
      state.screenDisplay.choosePlayerTeam = null;
      state.screenDisplay.additionalInfo = null;
      state.screenDisplay.flag = null;
    },
    setScreenDisplayChoosePlayerTeam(state, eventData) {
      state.screenDisplay.choosePlayerTeam = eventData;
      state.screenDisplay.showChooseTeam = false;
      state.screenDisplay.additionalInfo = null;
      state.screenDisplay.flag = null;
    },
    setAdditionalInfo(state, eventData) {
      state.screenDisplay.additionalInfo = eventData;
      state.screenDisplay.choosePlayerTeam = null;
      state.screenDisplay.showChooseTeam = false;
      state.screenDisplay.flag = null;
    },
    setFlagProcess(state, eventData) {
      state.screenDisplay.flag = eventData;
      state.screenDisplay.additionalInfo = null;
      state.screenDisplay.choosePlayerTeam = null;
      state.screenDisplay.showChooseTeam = false;
    }
  },
  getters: {
    getGameSession: (state) => state.gameSession,
    getScreenDisplay: (state) => state.screenDisplay,
  },
  actions: {
    gameSession(context, gameId) {
      const url = `/game-session/${gameId}`;
      AxiosApi.get(url).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    postNewEvent(context, data) {
      const url = `/event/`;
      AxiosApi.post(url, { ...data }).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    updateEvent(context, data) {
      const url = `/event/`;
      AxiosApi.patch(url, { ...data }).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    cancelPlaySubmit(context, data) {
      let url = `/event/${data.gameId}/`;
      if (data.eventId) url = `${url}${data.eventId}/`
      AxiosApi.delete(url).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    flagSubmitAction(context, data) {
      const url = '/flag/';
      AxiosApi.post(url, {...data}).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      })
    },
    changeGameSessionAction(context, data) {
      const url = '/game-session/';
      AxiosApi.patch(url, {...data}).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    addPlayerAction(context, data) {
      const url = '/players/';
      AxiosApi.post(url, { ...data }).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    editPlayerAction(context, data) {
      const url = '/players/';
      AxiosApi.patch(url, { ...data }).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    deletePlayerAction(context, data) {
      const url = `/players/${data.gameId}/${data.playerId}`;
      AxiosApi.delete(url).then(({ data }) => {
        context.commit('setGameSession', data.gameSession);
        context.commit('setSelectStartScreenDisplay', data.gameSession);
      });
    },
    showTeamToggle(context, showChooseTeam) {
      context.commit('setScreenDisplayChooseTeam', !showChooseTeam);
    },
    setEmptyGameSession(context) {
      context.commit('clearGameSession');
    },
    updateChoosePlayerTeam(context, eventData) {
      context.commit('setScreenDisplayChoosePlayerTeam', eventData);
    },
    showAdditionalInfo(context, additionalInfo) {
      context.commit('setAdditionalInfo', additionalInfo);
    },
    runFlagProcess(context, eventData) {
      context.commit('setFlagProcess', eventData);
    }
  },
};
