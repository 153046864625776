import Login from '@/views/public/Login.vue';
// eslint-disable-next-line no-unused-vars
import PublicPageLayout from '@/layouts/PublicPageLayout.vue';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'PublicPageLayout',
      title: 'login',
    },
  },
];
