const isAuthorizated = (conn) => {
  if (localStorage.getItem('at') && localStorage.getItem('expires')) {
    let utc = new Date();
    const offset = utc.getTimezoneOffset();
    utc = new Date(utc.getTime() + offset * 60000);
    try {
      // eslint-disable-next-line no-new
      new Date(localStorage.getItem('expires'));
    } catch (error) {
      return false;
    }
    if (new Date(localStorage.getItem('expires')) > utc) {
      return true;
    }
    const result = false;
    refreshToken(conn).then((result) => {
      if (result) {
        result = true;
      }
    });
    return result;
  }
  return false;
};

const setToken = (token, expires) => {
  localStorage.setItem('at', token);
  localStorage.setItem('expires', expires);
};

const destroyToken = () => {
  localStorage.removeItem('at');
  localStorage.removeItem('expires');
};

const userLogin = (conn, userCredentials) => new Promise((resolve, reject) => {
  conn
    .post(
      '/auth/login/',
      {
        username: userCredentials.username,
        password: userCredentials.password,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      },
    )
    .then((response) => {
      // eslint-disable-next-line camelcase
      const { accessToken, expires } = response.data;
      setToken(accessToken, expires);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

const getToken = () => localStorage.getItem('at');

const userLogout = (conn) => {
  destroyToken();
  conn.post('/auth/logout/').catch(() => {});
};

const refreshToken = async (conn) => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    withCredentials: true,
  };
  return conn
    .get('/auth/refresh/', { headers })
    .then((response) => {
      // eslint-disable-next-line camelcase
      const { accessToken, expires } = response.data;
      setToken(accessToken, expires);
      return true;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        destroyToken();
      }
      return false;
    });
};

export {
  isAuthorizated, getToken, userLogin, userLogout, refreshToken,
};
