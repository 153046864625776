import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { isAuthorizated } from './services/auth-service';
import { AxiosApi } from './services/axios-api';

const preparedRouter = (to, from, loggedStatus) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (loggedStatus) {
      return {};
    }
    return { name: 'login' };
  }
  if (loggedStatus) {
    return { name: 'tournaments' };
  }
  if (to.path === '/login' || to.path === '/login/') {
    return {};
  }
  return { name: 'login' };
};

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || 'MyFF';
  const loginStatus = isAuthorizated(AxiosApi);
  const changeRouter = preparedRouter(to, from, loginStatus);
  if (Object.keys(changeRouter).length) {
    next(changeRouter);
  } else {
    next();
  }
});

createApp(App).use(store).use(router).mount('#app');
