<template lang="pug">
.game-info-edit
  .score-box
    .team(:class="{'has-ball': attackTeamId === gameSession.homeTeam.id}" @click="attackTeamId=gameSession.homeTeam.id") {{ gameSession.homeTeam.name }}
    .info
      input(type="number" v-model="homeScore" :placeholder="homeScore").score
      span.score :
      input(type="number" v-model="awayScore" :placeholder="awayScore").score
    .team(:class="{'has-ball': attackTeamId === gameSession.awayTeam.id}" @click="attackTeamId=gameSession.awayTeam.id") {{ gameSession.awayTeam.name }}
  fieldset
    legend Выберете даун
    select(name="currentDown" v-model="currentDown").select-down
      option(value=1 :selected="gameSession.currentDown == 1") Down 1
      option(value=2 :selected="gameSession.currentDown == 2") Down 2
      option(value=3 :selected="gameSession.currentDown == 3") Down 3
      option(value=4 :selected="gameSession.currentDown == 4") Down 4
  fieldset
    legend Выберете таргет даун
    select(name="targetDowns" v-model="targetDowns").select-down
      option(value="center" :selected="gameSession.targetDowns == 'center'") Center
      option(value="goal"  :selected="gameSession.targetDowns == 'goal'") Goal
  fieldset
    legend Выберете половину игры
    select(name="half" v-model="half").select-down
      option(value=1  :selected="gameSession.half == 1") 1ая Половина
      option(value=2  :selected="gameSession.half == 2") 2ая Половина
      option(:value="gameSession.half + 1"  :selected="gameSession.half > 2") Овертайм
  button.confirm(@click="confirmChanges") Подтвердить
  button.cancel(@click="cancelEditing") Отменить
</template>

<script>
export default {
  name: 'GameInfoEdit',
  props: {
    gameSession: {
      type: Object,
    }
  },
  data() {
    return {
      attackTeamId: null,
      homeScore: null,
      awayScore: null,
      half: null,
      currentDown: null,
      targetDowns: null,
    }
  },
  methods: {
    cancelEditing() {
      this.resetChanges;
      this.$emit('cancel-edit');
    },
    resetChanges() {
      this.attackTeamId = this.gameSession.attackTeamId;
      this.homeScore = this.gameSession.homeScore;
      this.awayScore = this.gameSession.awayScore;
      this.half = this.gameSession.half;
      this.currentDown = this.gameSession.currentDown;
      this.targetDowns = this.gameSession.targetDowns;
    },
    confirmChanges() {
      const data = {};
      if (this.attackTeamId != this.gameSession.attackTeamId) {
        data.attackTeamId = this.attackTeamId;
      }
      if (this.homeScore != this.gameSession.homeScore) {
        data.homeScore = this.homeScore;
      }
      if (this.awayScore != this.gameSession.awayScore) {
        data.awayScore = this.awayScore;
      }
      if (this.half != this.gameSession.half) {
        data.half = this.half;
      }
      if (this.currentDown != this.gameSession.currentDown) {
        data.currentDown = this.currentDown;
      }
      if (this.targetDowns != this.gameSession.targetDowns) {
        data.targetDowns = this.targetDowns;
      }
      if (Object.keys(data).length) {
        this.$emit('confirm-game-session', data);
      } else {
        this.cancelEditing();
      }
    }
  },
  mounted() {
    this.resetChanges();
  },
};
</script>

<style scope lang="stylus">
.game-info-edit
  padding 5px 10px
  margin-bottom 20px
  border-radius 5px
  background-color #f5f5f8
  .score-box
    .team
      font-size 20px
      text-align center
      padding 2px 4px
      border 3px solid #f5f5f8
      box-sizing border-box
      width 40%
    input[type="radio"]
      display: none
    input[type="number"]
      margin-top 0
      padding 5px
      height auto
      background-color white
    .info
      width 100px
      span
        margin 0 5px
  .has-ball
    border-color #009063 !important
  .select-down
    padding 5px 10px
    margin 10px 0
    width 100%
  fieldset
    margin 10px 0
.cancel
  background-color white
.confirm
  background-color #ccc
</style>
