<template lang="pug">
.back-button
  router-link(
  tag="button",
  :to="{ name: backUrlName || 'tournaments'}",
) <<< Back
</template>

<script>
export default {
  name: 'BackButton',
  props: ['backUrlName'],
};
</script>

<style scoped lang="stylus"></style>
