export default [
  // {
  //   path: '/:tournamentId/games/',
  //   name: 'games',
  //   component: VacanciesDashboard,
  //   meta: {
  //     requiresLogin: true,
  //     title: 'Список Игр',
  //   },
  // },
  // {
  //   path: '/live-game/',
  //   name: 'vacancies-create',
  //   component: VacanciesCreate,
  //   meta: {
  //     requiresLogin: true,
  //     title: 'Ввод статистики',
  //   },
  // },
];
