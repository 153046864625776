<template lang="pug">
.player-info
  p.player-number {{ player.jerseyNumber }}
  p.player-name {{ player.firstName }} {{ player.lastName }}
</template>

<script>
export default {
  name: 'PlayerItem',
  props: {
    isEditable: {
      type: Boolean,
    },
    player: {
      type: Object,
    },
  },
};
</script>

<style scope lang="stylus">
.player
  height 24px
  display flex
  &-info
    display flex
    align-items center
    gap 10px
  &-number
    margin 0
    width 28px
    font-size 22px
    text-align right
  &-name
    margin 0
</style>
