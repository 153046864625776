<template lang="pug">
.player-select
  h2.team {{ team.name }}
  .editing(v-if="isEditing")
    PlayerForm(
      :player="editedPlayer"
      :teamId="team.id"
      @create-player="submitCreatedPlayer"
      @edit-player="submitEditedPlater"
      @cancel-editing="cancelEditing"
    )
  .editing(v-else)
    ul.player-list(v-if="isEditable")
      li.player-item(v-for="player in team.players")
        PlayerItem(:player="player" :isEditable="isEditable")
        .player-buttons
          span.player-edit(@click="editPlayer(player)") Ред
          span.player-delete(@click="deletePlayer(player.id)") Дел
      button(v-if="isEditable" @click="createPlayer") Добавить игрока
      button(@click="confirmRoster") {{ setRosterLabel }}
    .player-list(v-else)
      .player-item.player-item--select
        PlayerItem(v-for="player in team.players" :key="player.id"  :player="player" :isEditable="isEditable" @click="chooseenPlayerEmit(eventData.eventType, eventData.eventId, eventData.isAdditionalInfo, gameId, player.id)")
</template>

<script>
import PlayerItem from '@/components/players/PlayerItem';
import PlayerForm from '@/components/players/PlayerForm';

export default {
  name: 'PlayersList',
  components: { PlayerItem, PlayerForm },
  props: {
    isEditable: {
      Boolean,
    },
    gameId: {
      type: Number,
    },
    eventData: {
      type: Object,
    },
    team: {
      type: Object,
    },
    setRosterLabel: {
      type: String,
    }
  },
  data() {
    return {
      isEditing: false,
      editedPlayer: null,
    }
  },
  methods: {
    confirmRoster() {
      this.$emit('confirmRoster');
    },
    createPlayer() {
      this.isEditing = true;
    },
    submitCreatedPlayer(data, teamId) {
      this.isEditing = false;
      this.editedPlayer = null;
      this.$emit('submit-create-player', data, teamId);
    },
    deletePlayer(playerId) {
      this.isEditing = false;
      this.editedPlayer = null;
      const data = {
        playerId,
      }
      this.$emit('submit-delete-player', data);
    },
    editPlayer(player) {
      this.editedPlayer = player;
      this.isEditing = true;
    },
    submitEditedPlater(data, teamId) {
      this.isEditing = false;
      this.editedPlayer = null;
      this.$emit('submit-edit-player', data, teamId);
    },
    cancelEditing() {
      this.editedPlayer = null;
      this.isEditing = false;
    },
    chooseenPlayerEmit(eventType, eventId, isAdditionalInfo, gameId, playerId) {
      const data = {
        eventType,
        gameId,
      };
      if (playerId) {
        if (isAdditionalInfo) {
          if (!data.additionalInfo) data.additionalInfo = {};
          data.additionalInfo.playerId = playerId;
        } else {
          data.playerId = playerId;
        }
      }
      if (eventId) {
        data.eventId = eventId;
      }
      this.$emit('player-choosen', data);
    },
  },
};
</script>

<style scope lang="stylus">
.player
  &-select
    margin-bottom 20px
  &-list
    list-style none
    padding 0
    display flex
    flex-direction column
    gap 10px
  &-item
    display flex
    justify-content space-between
    gap 10px
    &--select
      flex-direction column
  &-info:active,
  &-info:focus
    color red
  &-buttons
    display flex
    gap 10px
    font-size 14px
    & span
      width 30px
      display flex
      align-items center
      justify-content center
      background-color #F5F5F8
      border 1px solid #A193A3
      border-radius 5px
.player-item--select
  & .player-info
      background-color #F5F5F8
      border-radius 5px
      padding 6px 12px
.players-list
    display flex
    flex-direction: column
    height calc(100vh - 90px)
</style>
