<template lang="pug">
.flag
    .section(v-if="currentDisplay == 'chooseTeam'")
        p Команда нарушившая правила 
        button(@click="setTeam(homeTeam)") {{ homeTeam.name }}
        button(@click="setTeam(awayTeam)") {{ awayTeam.name }}
    .section(v-else-if="currentDisplay == 'choosePlayer'")
        .player-item(v-for="player in team.players" :key="player.id")
            PlayerItem(:player="player" :isEditable="false" @click="choosePlayer(player.id)")
        button(@click="choosePlayer(null)") Без игрока
    .section(v-else-if="currentDisplay == 'chooseAttackTeam'")
        p Выберете атакующую команду
        button(@click="chooseAttackTeamId(homeTeam.id)" :class="homeTeam.id == attackTeamId ? 'active' : null") {{ homeTeam.name }}
        button(@click="chooseAttackTeamId(awayTeam.id)" :class="awayTeam.id == attackTeamId ? 'active' : null") {{ awayTeam.name }}
    .section(v-else-if="currentDisplay === 'chooseNextDown'")
        p Слудующий даун
        button(@click="chooseNextDown(1)" :class="currentDown == 1 ? 'active' : null") 1
        button(@click="chooseNextDown(2)" :class="currentDown == 2 ? 'active' : null") 2
        button(@click="chooseNextDown(3)" :class="currentDown == 3 ? 'active' : null") 3
        button(@click="chooseNextDown(4)" :class="currentDown == 4 ? 'active' : null") 4
    .section(v-else-if="currentDisplay === 'chooseTargetDowns'")
        p Таргетдаун
            button(@click="setTargetDowns('center')") Центр
            button(@click="setTargetDowns('goal')") Гол
</template>

<script>
import PlayerItem from '@/components/players/PlayerItem'
export default {
    name: 'Flag',
    props: {
        homeTeam: {
            type: Object,
        },
        awayTeam: {
            type: Object,
        },
        attackTeamId: {
            type: Number,
        },
        currentDown: {
            type: Number
        },
        targetDowns: {
            type: String,
        }
    },
    components: { PlayerItem },
    data() {
        return {
            nextDisplay: {
                chooseTeam: "choosePlayer",
                choosePlayer: "chooseAttackTeam",
                chooseAttackTeam: "chooseNextDown",
                chooseNextDown: "chooseTargetDowns",
            },
            currentDisplay: "chooseTeam",
            team: null,
            playerId: null,
            currentDownLocal: null,
            targetDownsLocal: null,
            attackTeamIdLocal: null,

        }
    },
    methods: {
        setTeam(team) {
            this.team = team;
            this.currentDisplay = this.nextDisplay[this.currentDisplay];
        },
        choosePlayer(playerId) {
            this.playerId = playerId;
            this.currentDisplay = this.nextDisplay[this.currentDisplay];
        },
        chooseAttackTeamId(teamId) {
            this.attackTeamIdLocal = teamId;
            this.currentDisplay = this.nextDisplay[this.currentDisplay];
        },
        chooseNextDown(nextDown) {
            this.currentDownLocal = nextDown;
            this.currentDisplay = this.nextDisplay[this.currentDisplay];
        },
        setTargetDowns(nextTargetDowns) {
            this.targetDownsLocal = nextTargetDowns;

            const data = {
                teamId: this.team.id,
                currentDown: this.currentDownLocal,
                targetDowns: this.targetDownsLocal,
                attackTeamId: this.attackTeamIdLocal,
            }
            if (this.playerId) data.playerId = this.playerId;
            this.$emit('submit-flag', data);
        }
    },
    mounted() {
        this.currentDownLocal = this.currentDown;
        this.targetDownsLocal = this.targetDowns;
        this.attackTeamIdLocal = this. attackTeamId;
    }
};
</script>

<style scope lang="stylus">
.players-list
    display flex
    flex-direction: column
    height calc(100vh - 90px)
.flag
    button
        margin-bottom: 10px
.active
    background-color #ccc
</style>
