<template lang="pug">
.player
    .form
        .input-field
            .label Номер
            input(v-model="newPlayer.jerseyNumber" placeholder="Номер" type="number")
            .error(v-if="error") {{ error }}
        .input-filed
            .label Имя
            input.name(v-model="newPlayer.firstName" placeholder="Имя" type="text")
        .input-filed
            .label Фамилия
            input.name(v-model="newPlayer.lastName" placeholder="Фамилия")
        button(v-if="!newPlayer.id" @click="createPlayer") Создать игрока
        button(v-else @click="editPlayer") Редактировать игрока
        button.cancel(@click="cancelEditing") Отменить
</template>

<script>
export default {
  name: 'PlayerForm',
  data() {
    return {
      newPlayer: {
        id: null,
        jerseyNumber: null,
        firstName: null,
        lastName: null,
      },
      error: '',
    };
  },
  props: {
    teamId: {
      type: Number,
    },
    player: {
      type: Object,
    },
  },
  methods: {
    createPlayer() {
      this.error = '';
      if (this.newPlayer.jerseyNumber) {
        const data = {
          jerseyNumber: this.newPlayer.jerseyNumber,
          firstName: this.newPlayer.firstName,
          lastName: this.newPlayer.lastName,
        }
        this.$emit("create-player", data, this.teamId);
      } else {
        this.error = 'Поле номер обязательно';
      }
    },
    editPlayer() {
      this.error = '';
      if (!this.newPlayer.jerseyNumber) {
        this.error = 'Поле Номер обязательно';
        return
      }
      const data = {
        id: this.player.id,
        jerseyNumber: this.newPlayer.jerseyNumber,
      }
      if (this.newPlayer.firstName) {
        data.firstName = this.newPlayer.firstName
      }
      if (this.newPlayer.lastName) {
        data.lastName = this.newPlayer.lastName
      }
      this.$emit("edit-player", data, this.teamId);
    },
  },
  mounted() {
    if (this.player) {
      this.newPlayer = this.player;
    }
  },
};
</script>

<style scope lang="stylus">
.player
    height 24px
.number
    font-size 22px
.form
  button
    margin-top 20px
.error
  color red
</style>
