<template lang="pug">
.login
  .form
    input(type="text", placeholder="Write your Name" v-model="username")
    input(type="password", placeholder="Password" v-model="password")
    .error(v-if="error") {{ error }}

  .actions
    button(type="button" @click="submitForm") Login
    button.reset Cancel
</template>

<script>
import { userLogin } from '@/services/auth-service';
import { AxiosApi } from '@/services/axios-api';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      is_auth: false,
      username: '',
      password: '',
      error: '',
    };
  },
  methods: {
    submitForm() {
      this.error = '';
      userLogin(AxiosApi, {
        username: this.username,
        password: this.password,
      })
        .then(() => {
          // TODO need redirect to previously page if exists
          this.$router.push({ name: 'tournaments' });
        })
        .catch((err) => {
          this.error = err.response.data.detail;
        });
    },
  },
};
</script>

<style scoped lang="stylus">
.login
  max-width 600px
  width 100%
  height 100vh
  background-color #fff
  display flex
  flex-direction column
  justify-content center

.form
  padding 16px

.actions
  padding 16px
  width 100%
  display flex
  flex-direction: column
  gap 15px

button.reset
  background-color #FFEEEE
  color #EF4650

.titles--title
  font-size 42px
  font-weight 300
.titles--text
  font-size 16px
  font-weight 300
  display flex
  justify-content center
  align-items center
</style>
