import { AxiosApi } from '@/services/axios-api';

export default {
  state: {
    tournaments: [],
  },
  mutations: {
    setTournaments(state, tournaments) {
      state.tournaments = tournaments;
    },
    cleanTournaments(state) {
      state.tournaments = [];
    },
  },
  getters: {
    getTournaments: (state) => state.tournaments,
  },
  actions: {
    async tournaments(context) {
      context.commit('cleanTournaments');
      const url = '/tournaments/';
      await AxiosApi.get(url).then(({ data }) => {
        context.commit('setTournaments', data.records);
      });
    },
  },
};
