<template>
  <router-view />
</template>

<style lang="stylus">
*
  box-sizing border-box

html, body
  padding 0
  margin 0
  background-color white

button
  box-shadow none
  background-color #F5F5F8
  cursor pointer
  border none
  padding 15px 25px
  font-size 18px
  font-weight 300
  color #121212
  letter-spacing: 1px;
  width 100%
  border-radius 8px

input
  border: none
  background-color #F5F5F8
  font-size 18px
  width 100%
  height 60px
  padding 24px
  border-radius 8px
  color #8F8FA7
  margin-top 16px

input:focus
  color #121212
  background-color #fff

#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  padding 30px 10px

a
  cursor pointer
  text-decoration none
  color #121212

  .error
    color red;
</style>
