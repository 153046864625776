import { createRouter, createWebHistory } from 'vue-router';
import publicPagesRouter from '@/router/publicPagesRouter';
import Tournaments from '@/views/internal/Tournaments';
import Games from '@/views/internal/Games';
import SomethingWentWrong from '@/views/errors/SomethingWentWrong';
import LiveGame from '@/views/internal/LiveGame';
import gameOperatorRouter from './gameOperatorRouter';

const routes = [
  ...gameOperatorRouter,
  ...publicPagesRouter,
  {
    path: '/tournaments',
    name: 'tournaments',
    component: Tournaments,
    meta: {
      title: 'Турниры',
      requiresLogin: true,
    },
  },
  {
    path: '/tournaments/:tournamentId/games',
    name: 'games',
    component: Games,
    meta: {
      title: 'Игры',
      requiresLogin: true,
    },
  },
  {
    path: '/live-game/:liveGameId',
    name: 'liveGame',
    component: LiveGame,
    meta: {
      title: 'Трансляция',
      requiresLogin: true,
    },
  },
  {
    path: '/something-went-wrong',
    name: 'something-went-wrong',
    component: SomethingWentWrong,
    meta: {
      layout: 'PublicPageLayout',
      title: 'Oooooooops',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
