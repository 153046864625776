<template lang="pug">
ul.games-list
  router-link(
    v-for="game in games" key:="game.id"
    :to="{ name: 'liveGame', params: { liveGameId: game.id } }"
  )
    .games-item
      span.games-id №{{game.id}}
      span {{ game.homeTeam.name }} : {{ game.awayTeam.name }}
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Games',
  computed: {
    ...mapState(['games']),
    ...mapGetters(['getGames']),
    games: (store) => store.getGames,
  },
  mounted() {
    this.$store.dispatch('games', this.$route.params.tournamentId);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.games
  &-list
    list-style none
    padding 0
  &-item
    border-radius 12px
    margin-bottom: 16px
    padding: 12px 24px
    background-color #F5F5F8
    font-size 21px
    text-align center
    display flex
    justify-content space-evenly
    align-items center
    gap 20px
  &-id
    font-size 14px
</style>
